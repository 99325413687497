import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import { datePickerTheme } from '../../../modules/resources/themes'
import ReactExport from "react-data-export";

import { eventReportStyles, tableStyles } from '../../../modules/resources/styles'
import { getInventoryReport, setInputEventReport, validateDates, getFilterData, clearState } from '../../../modules/core/EventReports/actions'
import { setInputUser } from '../../../modules/core/User/actions'

import { colors } from '../../../modules/resources/colors'
import { string_general } from '../../../modules/resources/strings'
import Dropdown from '../../components/Dropdown'
import Table from '../../components/Table'
import DatePicker from '../../components/DatePicker'

import Swal from 'sweetalert2';

// MATERIAL
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications/lib/notifications.css';


//test table
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../../../modules/resources/css/Table.css";
import { ReportCheckbox } from '../../components/Checkbox';
import jsPDF from 'jspdf';

const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Reports extends React.Component {

  state = {
    numberPages: 100,
    loading: false,
    startDate: new Date(),
    endDate: new Date(),
    report: true,
    pdf: false,
  }

  componentDidMount() {
    this.props.clearState()
    this.props.getFilterData({
      token: this.props.user.token,
      idEvent: this.props.match.params.event,
      idCustomer: this.props.idCustomerSelected,
      loadReport: () => this.loadReport(this.props.idCustomer, this.props.idProduct, this.props.startDateFormat, this.props.endDateFormat)
    })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/events`,
          label: 'Events'
        },
        {
          link: `/events`,
          label: this.props.infoLabel
        },
        {
          link: `/event/${this.props.match.params.event}/reports`,
          label: 'Report'
        },
        {
          link: `/event/${this.props.match.params.event}/inventoryReport`,
          label: 'Inventory Report'
        }]
    })
  }

  loadReport(idCustomer, idProduct, startDateFormat, endDateFormat) {
    this.setState({ loading: true })
    this.props.getInventoryReport({
      token: this.props.user.token,
      idEvent: this.props.match.params.event,
      idCustomer: idCustomer,
      idProduct: idProduct,
      startDate: startDateFormat,
      endDate: endDateFormat,
      hideLoading: () => this.setState({ loading: false }),
    })
  }

  getImageRows = (i) => {
    return {
      value: `image ${i}`,
      style: {
        fill: {
          patternType: "solid",
          fgColor: {
            rgb: "2e85d2"
          }
        },
        font: {
          sz: "18",
          bold: true,
          color: { rgb: "FFFFFFFF" }
        }
      }
    }
  }

  getImageRowsValues = (images) => {
    if (!images) return {
      image1: this.rowValue(),
      image2: this.rowValue(),
      image3: this.rowValue()
    }
    return {
      image1: this.extractImageUrls(images[0]),
      image2: this.extractImageUrls(images[1]),
      image3: this.extractImageUrls(images[2])
    }
  }

  rowValue = (value) => ({
    value: value || '-',
    style: {
      font: {
        sz: "12",
      }
    }
  })

  extractImageUrls = (image) => {
    const backendUrl = string_general.base_URL.replace("/api/", "");
    if (!image) return this.rowValue();

    if (typeof image === 'string') return this.rowValue(backendUrl + image);

    const publicImage = image.isPublic === true;
    if (!publicImage) return this.rowValue();

    return this.rowValue(backendUrl + image.url);
  }

  onChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
  };

  getImagesForPDF = () => {
    const reportData = [];
    this.props.reportList.forEach(({ images, deliveriedDate, driverFullName, site }) => {
      if (images) {
        images.forEach(({ url }) => reportData.push({ url, deliveriedDate, driverFullName, site }));
      };
    });
    return reportData;
  };

  generatePDF = async (imagesWithReportData) => {
    const pdf = new jsPDF();
    const backendUrl = string_general.base_URL.replace("/api/", "");

    const x = 5;
    const y = 5;
    pdf.setFontSize(12);

    imagesWithReportData.forEach(({ url, deliveriedDate, driverFullName, site }, index) => {
      try {
        const imgUrl = `${backendUrl}${url}`;
        if (index > 0) pdf.addPage();

        pdf.addImage(imgUrl, 'JPEG', x, y, 200, 260);
        pdf.text(`Date: ${deliveriedDate}, Site: ${site}, Driver Name: ${driverFullName}`, x, 280,);
      } catch (err) {
        console.log(err);
      }
    });
    pdf.save(`Inventory ${this.props.startDateFormat} to ${this.props.endDateFormat}`);
  };

  exportReport = () => {
    if (!this.state.pdf) return;

    const imagesWithReportData = this.getImagesForPDF();
    if (imagesWithReportData.length === 0) return Swal.fire('Report Error', 'The report has no images', 'info');

    this.generatePDF(imagesWithReportData);
  };

  /**
   * Create an excel report export
   * @returns Excel
   */
  createDataExcel() {

    let rows = [
      [
        {
          value: "Dispatch Type",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Customer",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Site",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Driver",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        { //Just for transfers
          value: "State",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Supplier",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Other Supplier",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Product",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Gallons",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Delivery Date and Time",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Notes",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        this.getImageRows(1),
        this.getImageRows(2),
        this.getImageRows(3),
      ]
    ]

    this.props.reportList.forEach(element => {
      const { image1, image2, image3 } = this.getImageRowsValues(element.images);

      if (element.type != 'Transfer-In' && element.type != 'Transfer-Out') {
        rows.push([
          {
            value: element.type,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.customer,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.site,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.driverFullName,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.state,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.supplier,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.otherSupplier,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.product,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.gallons,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.deliveriedDate,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.notes,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          image1,
          image2,
          image3,
          {
            value: "",
          },
        ])
      } else {
        //Transfers
        rows.push([
          {
            value: element.type,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: '',
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.site,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.driverFullName,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.state,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.supplier,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.otherSupplier,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.product,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.gallons,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.deliveriedDate,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.notes,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          image1,
          image2,
          image3,
          {
            value: "",
          },
        ])
      }
    });

    return [
      {
        ySteps: 5, //will put space of 5 rows,
        columns: [
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 250 } },
          { title: "", width: { wpx: 250 } },
          { title: "", width: { wpx: 350 } },
          { title: "", width: { wpx: 350 } },
          { title: "", width: { wpx: 350 } },
        ],
        data: rows
      }
    ]
  }

  /**
   * Create an excel detailed report export
   * @returns Excel
   */
  createDataDetailedExcel() {

    let rows = [
      [
        {
          value: "Dispatch Type",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Customer",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Site",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Site Address",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Site City",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Site State",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Site Zip Code",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Driver",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        { //Just for transfers
          value: "State",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Supplier",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Other Supplier",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Product",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Gallons",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Delivery Date and Time",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        {
          value: "Notes",
          style: {
            fill: {
              patternType: "solid",
              fgColor: {
                rgb: "2e85d2"
              }
            },
            font: {
              sz: "18",
              bold: true,
              color: { rgb: "FFFFFFFF" }
            }
          }
        },
        this.getImageRows(1),
        this.getImageRows(2),
        this.getImageRows(3),
      ]
    ]

    this.props.reportList.forEach(element => {
      const { image1, image2, image3 } = this.getImageRowsValues(element.images);

      if (element.type != 'Transfer-In' && element.type != 'Transfer-Out') {
        rows.push([
          {
            value: element.type,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.customer,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.site,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.siteAddress,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.siteCity,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.siteState,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.siteZipCode,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.driverFullName,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.state,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.supplier,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.otherSupplier,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.product,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.gallons,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.deliveriedDate,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.notes,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          image1,
          image2,
          image3,
          {
            value: "",
          },
        ])
      } else {
        //Transfers
        rows.push([
          {
            value: element.type,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: '',
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.site,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          //** */
          {
            value: element.siteAddress,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.siteCity,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.siteState,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.siteZipCode,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.driverFullName,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.state,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.supplier,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.otherSupplier,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.product,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.gallons,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.deliveriedDate,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          {
            value: element.notes,
            style: {
              font: {
                sz: "15",
              }
            }
          },
          image1,
          image2,
          image3,
          {
            value: "",
          },
        ])
      }
    });

    return [
      {
        ySteps: 5, //will put space of 5 rows,
        columns: [
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 200 } },
          { title: "", width: { wpx: 250 } },
          { title: "", width: { wpx: 250 } },
          { title: "", width: { wpx: 350 } },
          { title: "", width: { wpx: 350 } },
          { title: "", width: { wpx: 350 } },
        ],
        data: rows
      }
    ]
  }

  render() {
    return (
      <div style={eventReportStyles.container}>

        <div style={eventReportStyles.containerFilters}>
          <div style={eventReportStyles.containerDivfilters}>
            <div style={eventReportStyles.containerDropdownFilters}>
              <label style={{ marginRight: 10, marginTop: 3 }}>{string_general.label_dropdown_show} </label>
              <Dropdown
                selectItem={this.state.numberPages}
                placeholder={string_general.placeholder_select_rows}
                options={string_general.numberPages}
                onChange={(value, description) => {
                  this.setState({ numberPages: value })
                }} />
              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_customer} </label>
              <Dropdown
                selectItem={this.props.idCustomer}
                placeholder={this.props.labelCustomers}
                disabled={this.props.idCustomerSelected !== 0}
                options={this.props.customerList}
                onChange={(value, description) => {
                  this.props.setInputEventReport({ prop: 'idCustomer', value: value })
                  this.loadReport(value, this.props.idProduct, this.props.startDateFormat, this.props.endDateFormat)
                }} />

              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_product} </label>
              <Dropdown
                selectItem={this.props.idProduct}
                placeholder={this.props.labelProducts}
                options={this.props.productList}
                onChange={(value, description) => {
                  this.props.setInputEventReport({ prop: 'idProduct', value: value })
                  this.loadReport(this.props.idCustomer, value, this.props.startDateFormat, this.props.endDateFormat)
                }} />
              <div style={eventReportStyles.containerButtonCreate}>
                {/* Generate the inventory report */}

                <ExcelFile
                  filename={`Inventory ${this.props.startDateFormat} to ${this.props.endDateFormat}`}
                  element={
                    <button style={eventReportStyles.buttonExport} onClick={this.exportReport}>
                      {string_general.label_button_export}
                    </button>
                  }>
                  {this.state.report && <ExcelSheet dataSet={this.createDataExcel()} name="Report" />}
                </ExcelFile>
              </div>

              <div style={eventReportStyles.containerButtonCreate}>
                {/* Generate the inventory detailed report */}
                <ExcelFile
                  filename={`Inventory-Detailed ${this.props.startDateFormat} to ${this.props.endDateFormat}`}
                  element={
                    <button disabled style={{ ...eventReportStyles.buttonExport, marginLeft: '0px' }} onClick={this.exportReport}>
                      {string_general.label_button_export_detailed}
                    </button>
                  }>

                  {this.state.report && (
                    <ExcelSheet
                      dataSet={this.createDataDetailedExcel()}
                      name="Detailed Report"
                    />)}
                </ExcelFile>
              </div>

            </div>
          </div>
        </div>

        <div style={{ display: 'flex', width: '80%', height: 50, justifyContent: 'end', gap: 30, alignItems: 'center' }}>

          <ReportCheckbox
            label='Create CSV Report'
            isCheked={this.state.report}
            name='report'
            onChange={this.onChange}
          />

          <ReportCheckbox
            label='Create Image PDF'
            isCheked={this.state.pdf}
            name='pdf'
            onChange={this.onChange}
          />

        </div>

        <div style={eventReportStyles.containerFilters}>
          <div style={eventReportStyles.containerDivfilters}>
            <div style={eventReportStyles.containerDropdownFilters}>
              <label style={{ marginRight: 20 }}>{string_general.label_dropdown_start_date} </label>
              <ThemeProvider theme={datePickerTheme}>
                <DatePicker
                  selectedDate={this.props.startDate}
                  validateDates
                  onChange={(date) => this.props.validateDates({
                    startDate: date, endDate: this.props.endDate, isStartDate: true, loadReport: (startDateFormat, endDateFormat) => {
                      this.loadReport(this.props.idCustomer, this.props.idProduct, startDateFormat, endDateFormat)
                    }
                  })}
                  label={string_general.placeholder_select}
                  disableFuture={true}
                ></DatePicker>
              </ThemeProvider>

              <label style={{ marginRight: 20, marginLeft: 30 }}>{string_general.label_dropdown_end_date} </label>
              <ThemeProvider theme={datePickerTheme}>
                <DatePicker
                  selectedDate={this.props.endDate}
                  onChange={(date) => this.props.validateDates({
                    startDate: this.props.startDate, endDate: date, isStartDate: false, loadReport: (startDateFormat, endDateFormat) => {
                      this.loadReport(this.props.idCustomer, this.props.idProduct, startDateFormat, endDateFormat)
                    }
                  })}
                  label={string_general.placeholder_select}
                  disableFuture={true}
                ></DatePicker>
              </ThemeProvider>

            </div>
          </div>
        </div>

        <div style={{ marginTop: 100 }}></div>

        <ReactTable
          showPaginationTop={false}
          showPaginationBottom={false}
          resizable
          defaultPageSize={2}
          minRows={2}
          pageSize={2}
          pages={1}
          loading={this.state.loading}
          data={this.props.gallonReport}
          columns={this.props.gallonColumns}
          style={tableStyles.table}
        />


        <Table
          numberPages={this.state.numberPages}
          loading={this.state.loading}
          columns={this.props.columns}
          items={this.props.reportListFilter} />



      </div>
    )
  }

}

const mapStateProps = (state) => {
  const { isAuthenticated, user, infoLabel, idCustomerSelected } = state.user
  const { customerList, productList, idCustomer, idProduct, startDate, endDate, reportList, gallonReport,
    columns, gallonColumns, startDateFormat, endDateFormat, labelCustomers, labelProducts } = state.eventReport

  return {
    isAuthenticated, user, infoLabel, idCustomerSelected, customerList, productList, idCustomer, idProduct, startDate,
    endDate, reportList, reportListFilter: reportList.filter(e => e.type === "Mobile" || e.type === "Stationary"),
    gallonReport, columns, gallonColumns, startDateFormat, endDateFormat, labelCustomers, labelProducts
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  getInventoryReport,
  setInputEventReport,
  validateDates,
  getFilterData,
  clearState,
  setInputUser
})(Reports)))
