import React from "react";
import _ from 'lodash'
import Swal from 'sweetalert2';

import {
    CLEAR_STATE_DISPATCHES,
    SET_INPUT_DISPATCH,
    LOADING_DISPATCHES,
    LOAD_DISPATCHES,
    LOAD_DISPATCH,
    LOAD_GROUPS_CREATE_DISPATCHES,
    LOAD_SITESLIST_CREATE_DISPATCHES,
    LOADING_GROUPS,
    LOADING_SITES,
    LOAD_SITES_CREATE_DISPATCHES,
    UPDATE_LIST_TO_CREATE_DISPATCHES,
    DELETE_LIST_TO_CREATE_DISPATCHES,
} from './types'

import {
    sw_get_customers_from_event,
    sw_get_dispatch_types,
    sw_get_sites_by_dispatch,
    sw_get_sites_by_dispatch_list,
    sw_get_drivers_event,
    sw_get_groups_event,
    sw_get_groups_event_create_dispatch,
    sw_get_dispatches,
    sw_create_dispatches,
    sw_update_priority_dispatch,
    sw_import_sites,
    sw_get_status,
    sw_get_dispatch,
    sw_update_dispatch
} from '../../services/DispatchApi'

import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { string_general } from '../../resources/strings'
import { showAlert, isEmpty, siteFailedCols, sitesToCheckCoordinatesCols } from '../../resources/Util'

export const clearState = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_STATE_DISPATCHES })
    }
}

export const createDispatches = (info) => {
    const { token, event, dispatches, history } = info
    return (dispatch) => {
        if (isEmpty(dispatches)) {
            showAlert({ msj: string_general.errorMsj.emptyDispatches, type: 3 })
            return false
        }

        let dispatchesRequest = dispatches.map(e => ({
            idDriver: e.idDriver,
            idSite: e.idSite,
            type: e.idType,
            idEvent: event
        }))

        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop: 'creatingDispatches', value: true }
        })

        sw_create_dispatches({
            token,
            idEvent: event,
            data: dispatchesRequest
        }).then(data => {

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'creatingDispatches', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_DISPATCH,
                        payload: { prop: 'creatingDispatches', value: false }
                    })
                    return false
                }

                showAlert({ msj: 'Dispatches created successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_DISPATCHES
                })
                history.push(`/event/${event}/dispatches`)
            } else {
                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'creatingDispatches', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}

export const updateDispatch = (info) => {
    const { token, event, id, oldDriver, newDriver, type, status, history, gallonInformation, notes } = info
    return (dispatch) => {

        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop: 'updatingDispatch', value: true }
        })

        sw_update_dispatch({
            token,
            id,
            oldDriver,
            newDriver: oldDriver === newDriver ? 0 : newDriver,
            type,
            status,
            idEvent: event,
            notes,
            gallonInformation
        }).then(data => {


            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'updatingDispatch', value: false }
                })
                return false
            }

            if (data.status === 200) {

                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    dispatch({
                        type: SET_INPUT_DISPATCH,
                        payload: { prop: 'updatingDispatch', value: false }
                    })
                    return false
                }

                showAlert({ msj: 'Dispatch update successful', type: 1 })
                dispatch({
                    type: CLEAR_STATE_DISPATCHES
                })
                history.push(`/event/${event}/dispatches`)
            } else {
                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'updatingDispatch', value: false }
                })
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}

export const getDispatch = (info) => {
    const { token, event, id, gotDispatch } = info

    return (dispatch) => {

        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop: 'updatingDispatch', value: true }
        })

        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop: 'gettingGetting', value: true }
        })

        const requests = [
            sw_get_dispatch({ token, id }),
            sw_get_dispatch_types({ token }),
            sw_get_drivers_event({ token, idEvent: event }),
            sw_get_status({ token }),
        ]

        Promise.all(requests).then(function (values) {

            const dispatchTemp = values[0].data

            const typeSelected = dispatchTemp.type.id
            const driverSelected = dispatchTemp.drivers.id
            const statusSelected = dispatchTemp.status.id
            const notes = dispatchTemp.notes
            const siteId = dispatchTemp.site.id
            const customer = dispatchTemp.site.fk_idCustomer
            const fullAddress = dispatchTemp.site.address + ', ' + dispatchTemp.site.city + ', ' + dispatchTemp.site.zipCode

            // TYPES
            const typesTemp = values[1].data
            let types = []
            if (typesTemp !== undefined) {
                typesTemp.forEach(e => types.push({
                    value: e.id,
                    description: e.description
                }))
            }

            // DRIVERS
            const driversTemp = values[2].data

            let drivers = []
            if (driversTemp !== undefined) {
                driversTemp.forEach(e => {
                    const areDriverInSite = e.dispatches.find(f => f.idSite === siteId) === undefined ? true : false
                    if (areDriverInSite && e.idCustomer === customer) {
                        drivers.push({
                            value: e.id,
                            description: e.driverFullName
                        })
                    }
                })
            }

            drivers.push({
                value: dispatchTemp.drivers.id,
                description: `${dispatchTemp.drivers.firstName} ${dispatchTemp.drivers.lastName}`
            })

            // STATUS
            const statusTemp = values[3].data
            let status = []
            if (statusTemp !== undefined) {
                statusTemp.forEach(e => status.push({
                    value: e.id,
                    description: e.status
                }))
            }

            //SITE
            const sites = [{
                value: siteId,
                description: dispatchTemp.site.siteName
            }]

            dispatch({
                type: LOAD_DISPATCH,
                payload: {
                    labelDispatchTypes: 'Select a type',
                    dispatchTypes: types,
                    labelSites: 'Site',
                    labelDrivers: 'Select a driver',
                    drivers,
                    labelStatus: 'Select a status',
                    status,
                    sites
                }
            })
            gotDispatch(typeSelected, driverSelected, statusSelected, notes, siteId, fullAddress)
        })
    }
}

export const getDispatches = (info) => {
    const { token, event, success, customer, history } = info

    return (dispatch) => {

        dispatch({
            type: LOADING_DISPATCHES,
            payload: {
                labelCustomers: 'Loading customers...',
                labelDispatchTypes: 'Loading types...',
                labelSites: 'Loading sites...',
                labelDrivers: 'Loading drivers...',
                labelGroups: 'Loading groups...'
            }
        })

        const requests = [
            sw_get_customers_from_event({ token, event }),
            sw_get_dispatch_types({ token }),
            sw_get_sites_by_dispatch_list({ token, idEvent: event }),
            sw_get_drivers_event({ token, idEvent: event }),
            sw_get_groups_event({ token, idEvent: event }),
            sw_get_dispatches({ token, event })
        ]

        Promise.all(requests).then(function (values) {

            // CUSTOMERS
            const customerTemp = values[0].data

            let customers = [{ value: 0, description: 'All' }]
            if (customerTemp !== undefined) {
                customerTemp.forEach(e => customers.push({
                    value: e.customer.id,
                    description: e.customer.customerName
                }))
            }

            // TYPES
            const typesTemp = values[1].data
            let types = [{ value: 0, description: 'All' }]
            if (typesTemp !== undefined) {
                typesTemp.forEach(e => types.push({
                    value: e.id,
                    description: e.description
                }))
            }

            // SITES
            const sitesTemp = values[2].data;
            let sites = [{ value: 0, description: 'All' }]
            if (sitesTemp !== undefined) {

                sortSitesByName(sitesTemp).forEach(item => {
                    sites.push({
                        description: item.siteName,
                        value: item.id
                    })
                })
            }
            sites = _.uniqWith(sites, _.isEqual)

            // DRIVERS
            const driversTemp = values[3].data
            let drivers = [{
                value: 0,
                description: "All",
                customer: 0
            }]
            if (driversTemp !== undefined) {
                driversTemp.forEach(e => drivers.push({
                    value: e.id,
                    description: e.driverFullName,
                    customer: e.idCustomer
                }))
            }
            // GROUPS
            const groupsTemp = values[4].data

            let groups = [{
                value: 0,
                description: "All"
            }]
            if (groupsTemp !== undefined) {
                groupsTemp.forEach((e, index) => groups.push({
                    value: index + 1,
                    description: e.groupNumber
                }))
            }

            groups.sort(function (a, b) {
                return a['description'] - b['description'];
            });

            // COLUMNS
            const columnsSortable = [
                {
                    Header: 'Edit',
                    accessor: 'manage',
                    headerStyle: tableStyles.header,
                    width: 100,
                    sortable: true,

                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Dispatch Type',
                    accessor: 'type',
                    headerStyle: tableStyles.header,
                    sortable: true,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                width: '100%',
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Site',
                    accessor: 'site',
                    headerStyle: tableStyles.header,
                    sortable: true,
                    width: 400,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Driver',
                    accessor: 'driver',
                    headerStyle: tableStyles.header,
                    sortable: true,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Group #',
                    accessor: 'group',
                    headerStyle: tableStyles.header,
                    sortable: true,

                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Priority Order #',
                    accessor: 'priority',
                    headerStyle: tableStyles.header,
                    sortable: true,

                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Last Date Modified',
                    accessor: 'updated_at',
                    headerStyle: tableStyles.header,
                    sortable: true,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                }
            ]
            const columnsWithoutSortable = columnsSortable.map(e => ({ ...e, sortable: false }))

            // DISPATCHES
            const tempDispatches = values[5].data
            let items = []
            tempDispatches.forEach(dispatch => {
                items.push(
                    {
                        id: `${dispatch.id}`,
                        customer: dispatch.idCustomer,
                        siteId: dispatch.idSite,
                        typeId: dispatch.type.id,
                        driverId: dispatch.idDriver,
                        manage: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/event/${event}/edit_dispatch/${dispatch.id}`)}>Edit</a>,
                        modify: <a style={{ color: colors.blue_sfs, textDecorationLine: 'underline', }} onClick={() => history.push(`/event/${event}/modify_dispatch/${dispatch.id}/${dispatch.idDriver}`)}>Modify</a>,
                        type: dispatch.type.description,
                        site: dispatch.site,
                        driver: dispatch.driverFullName,
                        group: dispatch.group,
                        priority: dispatch.priorityOrder,
                        updated_at: dispatch.updated_at
                    }
                )
            });
            const listItemsFilter = customer === 0 ? items : items.filter(e => e.customer === customer)
            dispatch({
                type: LOAD_DISPATCHES,
                payload: {
                    labelCustomers: 'Select',
                    customers,
                    labelDispatchTypes: 'Select',
                    dispatchTypes: types,
                    labelSites: 'Select',
                    sites,
                    labelDrivers: 'Select',
                    drivers: customer === 0 ? drivers : drivers.filter(e => (e.customer === customer || e.value === 0)),
                    labelGroups: 'Select',
                    groups,
                    columnsSortable,
                    columnsWithoutSortable,
                    items: items,
                    listItemsFilter: listItemsFilter,
                }
            })

            success()
        })
    }
}

export const setInputDispatch = ({ prop, value }) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop, value }
        })
    }
}

export const filterByCustomer = (info) => {
    const { listItems, value } = info
    return (dispatch) => {
        let filter = (value !== 0) ? listItems.filter(item => item.customer === value) : listItems
        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop: 'listItemsFilter', value: filter }
        })
    }
}

export const filterDispatches = (info) => {
    const { listItems, customer, type, site, group, groupLabel } = info
    return (dispatch) => {
        let filter = []

        if (customer === 0 && type === 0 && site === 0 && group === 0) {
            filter = listItems
        } else if (customer !== 0 && type === 0 && site === 0 && group === 0) {
            filter = listItems.filter(item => item.customer === customer)
        } else if (customer !== 0 && type !== 0 && site === 0 && group === 0) {
            filter = listItems.filter(item => (item.customer === customer && item.typeId === type))
        } else if (customer !== 0 && type === 0 && site !== 0 && group === 0) {
            filter = listItems.filter(item => (item.customer === customer && item.siteId === site))
        } else if (customer !== 0 && type === 0 && site === 0 && group !== 0) {
            filter = listItems.filter(item => (item.customer === customer && item.group === groupLabel))
        } else if (customer !== 0 && type !== 0 && site !== 0 && group === 0) {
            filter = listItems.filter(item => (item.customer === customer && item.typeId === type && item.siteId === site))
        } else if (customer !== 0 && type === 0 && site !== 0 && group !== 0) {
            filter = listItems.filter(item => (item.customer === customer && item.siteId === site && item.group === groupLabel))
        } else if (customer !== 0 && type !== 0 && site === 0 && group !== 0) {
            filter = listItems.filter(item => (item.customer === customer && item.typeId === type && item.group === groupLabel))
        } else if (customer === 0 && type !== 0 && site === 0 && group === 0) {
            filter = listItems.filter(item => item.typeId === type)
        } else if (customer === 0 && type !== 0 && site !== 0 && group === 0) {
            filter = listItems.filter(item => item.typeId === type && item.siteId === site)
        } else if (customer === 0 && type !== 0 && site === 0 && group !== 0) {
            filter = listItems.filter(item => item.typeId === type && item.group === groupLabel)
        } else if (customer === 0 && type !== 0 && site !== 0 && group !== 0) {
            filter = listItems.filter(item => item.typeId === type && item.siteId === site && item.group === groupLabel)
        } else if (customer === 0 && type === 0 && site !== 0 && group === 0) {
            filter = listItems.filter(item => item.siteId === site)
        } else if (customer === 0 && type === 0 && site !== 0 && group !== 0) {
            filter = listItems.filter(item => item.siteId === site && item.group === groupLabel)
        } else if (customer === 0 && type === 0 && site === 0 && group !== 0) {
            filter = listItems.filter(item => item.group === groupLabel)
        } else if (customer !== 0 && type !== 0 && site !== 0 && group !== 0) {
            filter = listItems.filter(item => (item.customer === customer && item.typeId === type && item.siteId === site && item.group === groupLabel))
        }


        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop: 'dispatchesFilters', value: filter }
        })
    }
}

export const filterByDriver = (info) => {
    const { listItems, customer, driver } = info
    return (dispatch) => {
        let filter = []

        if (driver !== 0) {
            filter = listItems.filter(item => item.driverId === driver)
        } else {
            customer !== 0 ?
                filter = listItems.filter(item => item.customer === customer) :
                filter = listItems
        }


        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop: 'dispatchesFilters', value: filter }
        })
    }
}

export const reorder = ({ data, list }) => {
    return (dispatch) => {

        let newData = []
        list.forEach(e => {
            const item = data.filter(f => f.id === e.id)[0]
            item === undefined ? newData.push(e) : newData.push(item)
        })

        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop: 'dispatchesFilters', value: data }
        })

        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop: 'dispatches', value: newData }
        })
    }
}


export const getGroups = (info) => {
    const { token, event } = info

    return (dispatch) => {

        dispatch({
            type: LOADING_GROUPS,
            payload: {
                labelGroups: 'Select'
            }
        })

        const requests = [
            sw_get_groups_event_create_dispatch({ token, idEvent: event })
        ]

        Promise.all(requests).then(function (values) {


            // GROUPS

            const groupsTemp = values[0].data
            let groups = [{
                value: 0,
                description: "All"
            }]
            if (groupsTemp !== undefined) {
                groupsTemp.forEach((e, index) => {
                    if (e.groupNumber !== "") {
                        groups.push({
                            value: index + 1,
                            description: e.groupNumber
                        })
                    }
                })
            }
            groups.sort(function (a, b) {
                return a['description'] - b['description'];
            });



            dispatch({
                type: LOAD_GROUPS_CREATE_DISPATCHES,
                payload: {
                    labelGroups: 'Select',
                    groups
                }
            })
        })
    }
}

export const getSitesList = (info) => {
    const { token, event, success } = info

    return (dispatch) => {

        dispatch({
            type: LOADING_SITES,
            payload: {
                labelGroups: 'Select'
            }
        })

        const requests = [
            sw_get_sites_by_dispatch({ token, idEvent: event })
        ]

        Promise.all(requests).then(function (values) {


            // GROUPS


            const sitesfTemp = values[0].data
            let sitesList = [{ value: 0, description: 'All' }]
            if (sitesfTemp !== undefined) {
                sitesfTemp.forEach(e => sitesList.push({
                    value: e.id,
                    description: e.siteName
                }))
            }
            sitesList = _.uniqWith(sitesList, _.isEqual)



            dispatch({
                type: LOAD_SITESLIST_CREATE_DISPATCHES,
                payload: {
                    labelGroups: 'Select',
                    sitesList
                }
            })

            success(sitesList)
        })
    }
}

export const getSites = (info) => {
    const { token, event, success, customer } = info

    return (dispatch) => {

        dispatch({
            type: LOADING_DISPATCHES,
            payload: {
                labelCustomers: 'Loading customers...',
                labelDispatchTypes: 'Loading types...',
                labelDrivers: 'Loading drivers...',
                labelGroups: 'Loading groups...'
            }
        })

        const requests = [
            sw_get_customers_from_event({ token, event }),
            sw_get_dispatch_types({ token }),
            sw_get_drivers_event({ token, idEvent: event }),
            sw_get_sites_by_dispatch({ token, idEvent: event }),
            sw_get_groups_event_create_dispatch({ token, idEvent: event }),
            sw_get_sites_by_dispatch_list({ token, idEvent: event })
        ]

        Promise.all(requests).then(function (values) {

            // CUSTOMERS
            const customerTemp = values[0].data
            let customers = [{ value: 0, description: 'All' }]
            if (customerTemp !== undefined) {
                customerTemp.forEach(e => customers.push({
                    value: e.customer.id,
                    description: e.customer.customerName
                }))
            }

            // TYPES
            const typesTemp = values[1].data
            let types = []
            if (typesTemp !== undefined) {
                typesTemp.forEach(e => types.push({
                    value: e.id,
                    description: e.description
                }))
            }


            // DRIVERS
            const driversTemp = values[2].data
            let drivers = []
            if (driversTemp !== undefined) {
                driversTemp.forEach(e => {
                    const dispatchesByDriver = e.dispatches.map(e => e.idSite)
                    drivers.push({
                        value: e.id,
                        description: e.driverFullName,
                        customer: e.idCustomer,
                        color: e.driverColor,
                        sites: dispatchesByDriver
                    })
                })
            }

            // SITES
            const sitesTemp = values[3].data;

            let sites = []
            if (sitesTemp !== undefined) {

                sortSitesByName(sitesTemp).forEach(e => {

                    let array = []
                    drivers.forEach(driver => {
                        const areDriverInSite = driver.sites.find(f => f === e.id) === undefined ? true : false
                        if (driver.customer === e.customer.id && areDriverInSite) {
                            array.push({
                                id: driver.value,
                                description: driver.description,
                                typeDispatch: 0,
                                color: driver.color,
                                isSelect: false
                            })
                        }
                    })

                    sites.push({
                        id: e.id,
                        customer: e.customer.id, // FILTER BY CUSTOMER
                        group: e.groupNumber, // FILTER BY GROUP
                        name: `${e.siteName}, ${e.address}, ${e.city}, ${e.state.description}`, // SHOW NAME SITE
                        customerName: e.customer.customerName, // SHOW NAME CUSTOMER
                        position: { lat: e.latitude, lng: e.longitude }, // POSITION
                        haveDispatch: (e.fk_idStatus === 1 ? true : false), // I NEED KNOW IF A SITE WAS ADDED A DISPATCH
                        isNew: false, // I NEED KNOW IF A DISPATCH IS NEW OR NOT BECAUSE I NEED CHANGE THE COLOR ICON IN THE MAP
                        drivers: _.orderBy(array, 'id', 'asc') // THE LIS DRIVERS FOR ADD DRIVER TO SITE AND CREATE A DISPATCH
                    })
                })
            }

            // GROUPS

            const groupsTemp = values[4].data
            let groups = [{
                value: 0,
                description: "All"
            }]
            if (groupsTemp !== undefined) {
                groupsTemp.forEach((e, index) => {
                    if (e.groupNumber !== "") {
                        groups.push({
                            value: index + 1,
                            description: e.groupNumber
                        })
                    }
                })
            }



            // COLUMNS
            const columns = [
                {
                    Header: 'Delete',
                    accessor: 'manage',
                    headerStyle: tableStyles.header,
                    width: 100,
                    sortable: false,
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) == 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Type',
                    accessor: 'type',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                width: '100%',
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Group #',
                    accessor: 'group',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                width: '100%',
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Site',
                    accessor: 'site',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    width: 600,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                },
                {
                    Header: 'Driver',
                    accessor: 'driver',
                    headerStyle: tableStyles.header,
                    sortable: false,
                    Cell: props => <span>{props.value}</span>, // Custom cell components!
                    getProps: (state, rowInfo, column) => {
                        return {
                            style: {
                                ...tableStyles.row,
                                background: (rowInfo !== undefined && (rowInfo.nestingPath[0] % 2) === 0) ? colors.row : "white"
                            }
                        }
                    }
                }
            ]

            // SITES TO FILTER
            const sitesfTemp = values[5].data
            let sitesf = [{ value: 0, description: 'All' }]
            if (sitesfTemp !== undefined) {
                sitesfTemp.forEach(e => sitesf.push({
                    value: e.id,
                    description: e.siteName
                }))
            }
            sitesf = _.uniqWith(sitesf, _.isEqual)



            dispatch({
                type: LOAD_SITES_CREATE_DISPATCHES,
                payload: {
                    labelCustomers: 'Select',
                    customers,
                    labelDispatchTypes: 'Select',
                    dispatchTypes: types,
                    labelSites: 'Select',
                    sites,
                    sitesFilter: customer === 0 ? sites : sites.filter(item => item.customer === customer),
                    labelDrivers: 'Select',
                    drivers,
                    labelGroups: 'Select',
                    groups,
                    columns,
                    sitesf
                }
            })
        })
    }
}

export const filterSites = (info) => {
    const { sites, customer, group, groupLabel, siteId } = info
    return (dispatch) => {
        let filter = []


        if (customer === 0 && group === 0 && siteId === 0) {
            filter = sites
        } else if (customer !== 0 && group === 0 && siteId === 0) {
            filter = sites.filter(item => item.customer === customer)
        } else if (customer === 0 && group !== 0 && siteId === 0) {
            filter = sites.filter(item => item.group === groupLabel)
        } else if (customer !== 0 && group !== 0 && siteId === 0) {
            filter = sites.filter(item => (item.customer === customer && item.group === groupLabel))
        } else if (customer !== 0 && group === 0 && siteId !== 0) {
            filter = sites.filter(item => (item.customer === customer && item.id === siteId))
        } else if (customer === 0 && group !== 0 && siteId !== 0) {
            filter = sites.filter(item => (item.group === groupLabel && item.id === siteId))
        }
        else if (customer === 0 && group === 0 && siteId !== 0) {
            filter = sites.filter(item => item.id === siteId)
        } else if (customer !== 0 && group === 0 && siteId !== 0) {
            filter = sites.filter(item => item.id === siteId && item.customer === customer)
        } else if (customer !== 0 && group !== 0 && siteId !== 0) {
            filter = sites.filter(item => item.id === siteId && item.customer === customer && item.group === groupLabel)

        }


        for (let index = 0; index < sites.length; index++) {
            const element = sites[index];
            element.value = element.id;
            element.description = element.name
        }
        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop: 'sitesFilter', value: filter }
        })

    }
}

export const driverSelect = (info) => {
    const { sites, sitesFilters, type, idSite, item, listItems, listItemsFilter, groups } = info


    return (dispatch) => {

        if (type === 0 && !item.isSelect) {
            showAlert({
                msj: 'Select a dispatch type',
                type: 2
            })
            return false
        }

        const site = sites.find(e => e.id === idSite)

        let nameDriver = item.description

        if (item.isSelect) {
            nameDriver = nameDriver.replace('(M) - ', '')
            nameDriver = nameDriver.replace('(S) - ', '')
        } else {
            const pre = type === 1 ? '(M) - ' : '(S) - '
            nameDriver = `${pre}${item.description}`
        }

        const drivers = [...site.drivers.filter(e => e.id !== item.id), {
            ...item,
            description: nameDriver,
            typeDispatch: type,
            isSelect: !item.isSelect
        }]

        const haveDispatch = drivers.find(e => e.isSelect) === undefined ? false : true
        const quantityDispatch = drivers.filter(e => e.isSelect).length
        const color = quantityDispatch !== 1 ? "#2e85d2" : drivers.find(e => e.isSelect).color

        const newSites = [...sites.filter(e => e.id !== site.id), {
            ...site,
            haveDispatch,
            color,
            drivers: _.orderBy(drivers, 'id', 'asc')
        }]
        const newSitesFilters = [...sitesFilters.filter(e => e.id !== site.id), {
            ...site,
            haveDispatch,
            color,
            drivers: _.orderBy(drivers, 'id', 'asc')
        }]

        let items = listItems
        let itemsFilters = listItemsFilter
        let id = `${idSite}${item.id}`

        if (!item.isSelect) {
            nameDriver = nameDriver.replace('(M) - ', '')
            nameDriver = nameDriver.replace('(S) - ', '')

            items.push({
                id,
                idSite,
                idDriver: item.id,
                idType: type,
                manage: <a onClick={() => {
                    nameDriver = nameDriver.replace('(M) - ', '')
                    nameDriver = nameDriver.replace('(S) - ', '')

                    const driver = {
                        ...item,
                        description: nameDriver,
                        typeDispatch: 0,
                        isSelect: false
                    }


                    dispatch({
                        type: DELETE_LIST_TO_CREATE_DISPATCHES,
                        payload: {
                            idRow: `${idSite}${item.id}`,
                            idSite,
                            driver
                        }
                    })
                }}>Delete</a>,
                type: type === 1 ? 'Mobile' : 'Stationary',
                group: site.group,
                site: site.name,
                driver: nameDriver
            })

            itemsFilters.push({
                id,
                idSite,
                idDriver: item.id,
                idType: type,
                manage: <a onClick={() => {
                    nameDriver = nameDriver.replace('(M) - ', '')
                    nameDriver = nameDriver.replace('(S) - ', '')

                    const driver = {
                        ...item,
                        description: nameDriver,
                        typeDispatch: 0,
                        isSelect: false
                    }


                    dispatch({
                        type: DELETE_LIST_TO_CREATE_DISPATCHES,
                        payload: {
                            idRow: `${idSite}${item.id}`,
                            idSite,
                            driver
                        }
                    })

                }}>Delete</a>,
                type: type === 1 ? 'Mobile' : 'Stationary',
                group: site.group,
                site: site.name,
                driver: nameDriver
            })
        } else {
            items = listItems.filter(e => e.id !== id)
            itemsFilters = listItemsFilter.filter(e => e.id !== id)
        }

        // 

        // .forEach(element => {

        // });

        dispatch({
            type: UPDATE_LIST_TO_CREATE_DISPATCHES,
            payload: {
                sites: newSites,
                sitesFilter: newSitesFilters,
                listItems: _.clone(items),
                listItemsFilter: _.clone(itemsFilters)
            }
        })
    }
}

export const addNewSiteFromCreate = (info) => {
    const { site, sites, sitesFilter, drivers, groupLabel } = info
    return (dispatch) => {

        let newDrivers = []
        drivers.forEach(driver => {
            if (driver.customer === site.customer) {
                newDrivers.push({
                    id: driver.value,
                    description: driver.description,
                    typeDispatch: 0,
                    color: driver.color,
                    isSelect: false
                })
            }
        })

        const newSite = { ...site, drivers: newDrivers }

        Swal.fire({
            title: "Do you want to see only the new site?",
            text: "See only the new site on the map and hide the other locations.",
            confirmButtonText: "Show only the new site",
            confirmButtonColor: colors.blue_sfs,
            showCancelButton: true,
            cancelButtonText: "Show all the sites"
        }).then((options) => {
            if (options.isConfirmed) {
                //Show only the new site
                sites.length = 0; //Reset the list
                sitesFilter.length = 0;
                sites.push(newSite) //Add the new site to the current sites state
                sitesFilter.push(newSite);

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesFilter', value: [newSite] }
                })

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesFilter', value: sitesFilter }
                })

            } else {
                sites.push(newSite) //Add the new site to the current sites state
                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sites', value: sites }
                })

                if (site.groupNumber === groupLabel || groupLabel === 'All') {
                    sitesFilter.push(newSite)
                    dispatch({
                        type: SET_INPUT_DISPATCH,
                        payload: { prop: 'sitesFilter', value: sitesFilter }
                    })
                }
            }
        })

    }
}

export const updatePriority = (info) => {
    const { token, data } = info
    return (dispatch) => {
        if (isEmpty(data)) {
            return false
        }
        sw_update_priority_dispatch({
            token,
            data
        })

    }
}

export const importSites = (info) => {
    const { token, idCustomer, fileTemp, setImporting, drivers, event } = info
    return (dispatch) => {

        setImporting(true)
        let data = new FormData();
        data.append('file', fileTemp);
        data.append('idCustomer', idCustomer);

        //Reset the listItems state
        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop: 'listItems', value: [] }
        })

        //Reset the listItemsFilter state
        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop: 'listItemsFilter', value: [] }
        })

        //Import the sites to tha database
        sw_import_sites({ token, data }).then(data => {
            setImporting(false)

            if (data === undefined) {
                showAlert({ msj: string_general.errorMsj.internet, type: 3 })
                return false
            }
            const { listSites: sitesTemp, success, message } = data.data;

            if (data.status === 200) {

                if (!success && success !== undefined) {
                    showAlert({ msj: message, type: 3 })
                    return false
                }

                showAlert({ msj: 'Sites import successful', type: 1 })

                let sites = []
                if (sitesTemp !== undefined) {
                    sitesTemp.forEach(site => {
                        let driversList = []

                        drivers.forEach(driver => {
                            if (!!driver.sites) {
                                const areDriverInSite = driver.sites.find(f => f === site.id) === undefined ? true : false
                                if (driver.customer === site.customer.id && areDriverInSite) {
                                    driversList.push({
                                        id: driver.value,
                                        description: driver.description,
                                        typeDispatch: 0,
                                        color: driver.color,
                                        isSelect: false
                                    })
                                }
                            }
                        })

                        sites.push({
                            id: site.id,
                            customer: site.customer.id, // FILTER BY CUSTOMER
                            group: site.groupNumber, // FILTER BY GROUP
                            name: `${site.siteName}, ${site.address}, ${site.city}, ${site.state.description}`, // SHOW NAME SITE
                            customerName: site.customer.customerName, // SHOW NAME CUSTOMER
                            position: { lat: site.latitude, lng: site.longitude }, // POSITION
                            haveDispatch: false, // I NEED KNOW IF A SITE WAS ADDED A DISPATCH
                            isNew: true, // I NEED KNOW IF A DISPATCH IS NEW OR NOT BECAUSE I NEED CHANGE THE COLOR ICON IN THE MAP
                            drivers: _.orderBy(driversList, 'id', 'asc') // THE LIS DRIVERS FOR ADD DRIVER TO SITE AND CREATE A DISPATCH
                        })
                    })

                }
                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sites', value: sites }
                })

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesFilter', value: sites }
                })


            } else {
                setImporting(false)
                showAlert({ msj: data.data.message, type: 3, duration: 10000 })
            }

            const { messageTxt, sitesRepeated } = data.data;
            if (sitesRepeated?.length > 0) {
                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'importSitesFailed', value: true }
                })

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesImportedError', value: messageTxt }
                })

                let dataToTable = [];

                sitesRepeated.forEach(siteRepeated => {
                    dataToTable.push({
                        colSiteName: siteRepeated[0].name,
                        colSiteAddress: `${siteRepeated[0].address}, ${siteRepeated[0].city}, ${siteRepeated[0].state}`,
                        colCurrentSiteName: siteRepeated[1].siteName,
                        colCurrentSiteAddress: `${siteRepeated[1].address}, ${siteRepeated[1].city}, ${siteRepeated[0].state}`
                    });
                });

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesRepeated', value: dataToTable } //String to implement on a text file.
                })

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesRepeatedCols', value: siteFailedCols } //String to implement on a text file.
                })
            }

            const { sitesCheckLocation, messageSiteCheckLocation } = data.data;

            if (sitesCheckLocation?.length > 0) {

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'hasToCheckCoodinates', value: true }
                })

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesCoodinatesError', value: messageSiteCheckLocation }
                })

                let dataToTable = [];

                sitesCheckLocation.forEach(site => {
                    dataToTable.push({
                        colSiteName: site.name,
                        colSiteAddress: `${site.address}, ${site.city}, ${site.state}`,
                    });
                });

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesToCheckCoodinates', value: dataToTable } //String to implement on a text file.
                })

                dispatch({
                    type: SET_INPUT_DISPATCH,
                    payload: { prop: 'sitesToCheckCoodinatesCols', value: sitesToCheckCoordinatesCols } //String to implement on a text file.
                })

            }

        })
    }
}

export const closeImportSitesErrorModal = (prop) => {
    return (dispatch) => {
        dispatch({
            type: SET_INPUT_DISPATCH,
            payload: { prop, value: false }
        })
    }
}

/**
 * Order the sites by name, putting first the name of the sites with letters, and then with numbers.
 * @param {*} sitesTemp Sites requested from the API
 * @return sitesTemp Array sorted
 */
function sortSitesByName(sitesTemp) {
    sitesTemp = sitesTemp.sort((a, b) => (a.siteName > b.siteName) ? 1 : -1)
    if (!sitesTemp || !sitesTemp[0]) {
        return sitesTemp;
    }
    if (!sitesTemp[0].siteName) {
        return sitesTemp;
    }

    const numbers = "1234567890"; // Const to filter the letters and numbers list. 
    let lettersList = [];
    let numbersList = sitesTemp.filter(item => {
        if (!numbers.includes(item.siteName[0])) {
            lettersList.push(item);
            return false;
        }
        return true;
    })

    lettersList = lettersList.sort((a, b) => (a.siteName > b.siteName) ? 1 : -1);
    numbersList = numbersList.sort((a, b) => (a.siteName > b.siteName) ? 1 : -1);

    sitesTemp = [...lettersList, ...numbersList];
    return sitesTemp;
}
