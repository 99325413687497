import React from "react";
import _ from 'lodash'
import {
    LOAD_TRANSFER,
    SET_INPUT_TRANSFER,
    CLEAR_STATE_TRANSFER,
    SET_PRODUCT_TRANSFER,
} from './types'

import { sw_initial_transfer, sw_send_transfer, sw_get_states } from '../../services/TransferApi'
import { string_general, string_delivery, string_transfer } from '../../../modules/resources/strings'
import { colors } from '../../resources/colors'
import { tableStyles } from '../../resources/styles'
import { showAlert, isEmpty } from '../../resources/Util'
import Moment from "moment";



export const setInputTransfer = ({ prop, value }) => {

    return (dispatch) => {
        dispatch({
            type: SET_INPUT_TRANSFER,
            payload: { prop, value }
        })
    }

}

export const setOtherSupplierDescription = ({ value, suppliers }) => {

    let drivers = suppliers.map(s => {
        if (s.value == -1) {
            return { ...s, description: value }
        } else {
            return s
        }
    })

    return (dispatch) => {
        dispatch({
            type: SET_INPUT_TRANSFER,
            payload: { prop: 'drivers', value: drivers }
        })
    }
}


export const getInitialData = (info) => {
    const { token, idEvent } = info;
    var dispatchPayload = {};
    return (dispatch) => {

        dispatch({
            type: SET_INPUT_TRANSFER,
            payload: { prop: 'creatingTransfer', value: true }
        })

        sw_get_states({ token }).then(data => {

            let states = [{ value: 0, description: string_transfer.label_state }]
            data.data.forEach(state => {
                states.push({ value: state.id, description: state.description })
            })
            dispatchPayload.states = states;
        })

        sw_initial_transfer({ token, idEvent }).then(data => {

            // console.log("DATA TRANSFER *************", data);

            let types = [{ value: 0, description: string_transfer.label_type_transfer }]
            data.data.types_transfer.forEach(type => {

                types.push(
                    {
                        value: type.id,
                        description: type.description
                    }
                )
            })


            let userDrivers = [{ value: 0, description: string_transfer.label_driver_transfer }]
            data.data.drivers.forEach(driver => {
                userDrivers.push(
                    {
                        value: driver.id,
                        description: driver.driverFullName
                    }
                )
            })



            let drivers = [{ value: 0, description: string_transfer.label_supplier_transfer }]
            data.data.drivers.forEach(driver => {
                drivers.push(
                    {
                        value: driver.id,
                        description: driver.driverFullName
                    }
                )
            })

            if (data.data.drivers.length > 0) {

                const itemTerminal = { key: '-2', value: -2, description: 'Terminal' };
                drivers.push(itemTerminal);

                const itemBulk = { key: '-3', value: -3, description: 'Bulk Plant' };
                drivers.push(itemBulk);

                const itemDriver = { key: '-1', value: -1, description: 'Other Supplier' };
                drivers.push(itemDriver);
            }


            let products = [{ value: 0, description: string_delivery.label_dropdown_product }]
            data.data.products.forEach(product => {
                products.push(
                    {
                        value: product.id,
                        description: product.productName
                    }
                )
            })

            dispatch({
                type: SET_INPUT_TRANSFER,
                payload: { prop: 'creatingTransfer', value: false }
            })
            dispatchPayload.types_transfer = types;
            dispatchPayload.drivers = drivers;
            dispatchPayload.products = products;
            dispatchPayload.userDrivers = userDrivers;
            // dispatchPayload.states = userDrivers;
            dispatch({
                type: LOAD_TRANSFER,
                payload: dispatchPayload
            })
        })
    }

}


export const addProduct = (info) => {
    const { idProduct, productName, gallons, deliveryProducts } = info
    return (dispatch) => {
        if (idProduct === 0 || gallons === '') {
            showAlert({ msj: string_delivery.product_empty_error_message, type: 2 })
            return false
        } else {

            let filter = deliveryProducts.filter(item => item.id === idProduct)
            if (filter.length !== 0) {
                showAlert({ msj: string_delivery.product_error_message, type: 2 })
                return false
            } else {
                if (isNaN(gallons)) {
                    showAlert({ msj: string_delivery.gallons_error_message, type: 2 })
                    return false
                } else {
                    let newProduct = {
                        id: idProduct,
                        description: productName,
                        gallons: gallons
                    }

                    let newDeliveryProducts = [...deliveryProducts, newProduct]
                    dispatch({
                        type: SET_PRODUCT_TRANSFER,
                        payload: { deliveryProducts: newDeliveryProducts }
                    })
                }
            }
        }
    }
}

export const deleteProduct = (info) => {
    const { deliveryProducts, item } = info
    return (dispatch) => {
        let filter = deliveryProducts.filter(e => e !== item)
        dispatch({
            type: SET_INPUT_TRANSFER,
            payload: { prop: 'deliveryProducts', value: filter }
        })
    }
}

export const clearState = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_STATE_TRANSFER
        })
    }
}



export const createTransfer = (info) => {
    const { token, idType, site, idUser, idDriver, listProducts, note, other, history, idEvent, idState } = info

    // let deliveryDate = Moment(date).format('YYYY-MM-DD')
    return (dispatch) => {



        if (listProducts.length == 0) {
            showAlert({ msj: string_delivery.empty_product_list_error_message, type: 2 })
            return false
        }

        if (idType == 0) {
            showAlert({ msj: 'You must select a type', type: 2 });
            return false;
        }

        if (idState <= 0) {
            showAlert({ msj: 'You must select a state', type: 2 });
            return false;
        }

        if (idDriver == 0) {
            showAlert({ msj: 'You must select a driver', type: 2 });
            return false;
        }

        if (idDriver == -1 && other == '') {
            showAlert({ msj: 'Change the name of the other supplier.', type: 2 });
            return false;
        }



        if (isEmpty(site)) {
            showAlert({ msj: 'The site field is required.', type: 2 });
            return false;
        }

        dispatch({
            type: SET_INPUT_TRANSFER,
            payload: { prop: 'creatingTransfer', value: true }
        })
        // backend is waiting for a string
        const stringListProduct = JSON.stringify(listProducts);


        sw_send_transfer({
            token,
            idType,
            idDriver,
            listProducts: stringListProduct,
            note,
            idUser,
            other,
            site,
            idState
        }).then(data => {
            dispatch({
                type: SET_INPUT_TRANSFER,
                payload: { prop: 'creatingTransfer', value: false }
            })

            if (data === undefined) {
                showAlert({ msj: string_general.internet_connection_error_message, type: 3 })
                return false
            }

            if (data.status === 200) {
                if (!data.data.success && data.data.success !== undefined) {
                    showAlert({ msj: data.data.message, type: 3 })
                    return false
                }
                var message = string_transfer.create_transfer_success_message
                dispatch({
                    type: CLEAR_STATE_TRANSFER
                })
                showAlert({ msj: message, type: 1 })
                history.push(`/event/${idEvent}/dispatches`)
            } else {
                // console.log("DATA ERROR: ", data)
                showAlert({ msj: data.data.error, type: 3 })
            }
        })

    }
}
