import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/core';
import { mainTheme } from '../../modules/resources/themes';

export const ReportCheckbox = ({ label, isCheked = false, onChange, name }) => {

    return (
        <ThemeProvider theme={mainTheme}>
            <div>
                <Checkbox
                    color='primary'
                    name={name}
                    checked={isCheked}
                    onChange={onChange}
                />
                <label style={{ marginRight: 20, fontSize: 18 }}>{label}</label>
            </div>
        </ThemeProvider>
    )
}
