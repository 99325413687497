/*global google*/
import React from 'react';
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import { string_general } from '../../../modules/resources/strings';

import { getIconTruck, getIconDispatch, MoreThanOneDriverIcon, hasMoreThanOneDriverFC } from '../../../modules/resources/Util'
import { infoWindowStyle } from '../../../modules/resources/styles';
import moment from 'moment';
import Table from '../../components/Table';

import './Map.css';



const MapContainer = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${string_general.api_key}&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `500px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)(props =>


    <GoogleMap
        options={{ scrollwheel: true }}
        defaultZoom={string_general.zoom}
        defaultCenter={new google.maps.LatLng(string_general.latitude, string_general.longitude)}>

        {props.drivers.map(driver => {
            const itemColor = (props.customerSelected === 0) ? driver.driverColor : (driver.driverColor) ? driver.driverColor : driver.driverColor
            const truckType = (driver.truck.truckType) ? driver.truck.truckType : 2;
            return (
                <Marker
                    key={`marker-${driver.driverFullName}`}
                    icon={{ url: getIconTruck(itemColor, truckType) }}
                    title={`${driver.driverFullName}`}
                    name={driver.driverFullName}
                    position={driver.position}
                    onClick={() => props.onChangeHoverKey(driver.id)}
                >
                    {props.hoverKey === driver.id &&
                        <InfoWindow
                            key={`infowindow-${driver.driverFullName}`}
                            onCloseClick={() => props.onChangeHoverKey(0)}
                        >
                            <div style={infoWindowStyle.container}>

                                <div style={infoWindowStyle.containerInfoCustomer}>
                                    <span style={infoWindowStyle.labelCustomer}>{`${driver.customer.customerName}`}</span>
                                </div>

                                <div style={infoWindowStyle.containerInfo}>

                                    <div style={infoWindowStyle.containerInfoDriverHelperTruck}>

                                        <span style={infoWindowStyle.titleInfo}>Driver Info</span>
                                        <span style={infoWindowStyle.labelInfo}>{`${driver.driverFullName}`}</span>
                                        <span style={infoWindowStyle.labelInfo}>{`${driver.email}`}</span>
                                        <span style={infoWindowStyle.labelInfo}>{`${driver.phone}`}</span>

                                    </div>

                                    <div style={infoWindowStyle.containerInfoDriverHelperTruck}>

                                        <span style={infoWindowStyle.titleInfo}>Helper Info</span>
                                        <span style={infoWindowStyle.labelInfo}>{`${driver.helper.helperFullName}`}</span>
                                        <span style={infoWindowStyle.labelInfo}>{`${driver.helper.email}`}</span>
                                        <span style={infoWindowStyle.labelInfo}>{`${driver.helper.phone}`}</span>

                                    </div>

                                </div>

                                {/* <div style={infoWindowStyle.containerTruckInfo}>

                                <div style={infoWindowStyle.containerInfoDriverHelperTruck}>
                                    <span style={infoWindowStyle.titleTruck}>Truck</span>
                                </div>

                                <div style={infoWindowStyle.containerInfoDriverHelperTruck}>
                                    <span style={infoWindowStyle.labelInfoTruck}>{`${driver.truck.truckNumber}`}</span>
                                </div>

                            </div> */}

                                <div style={infoWindowStyle.containerInfoCustomer}>
                                    <span style={infoWindowStyle.labelCustomer}> Truck #: {`${driver.truck.truckNumber}`} </span>

                                </div>
                                <div style={infoWindowStyle.containerInfoCustomer}>
                                    <span style={infoWindowStyle.labelCustomer}> Last Seen:  {moment(`${driver.lastSeen}`).format('LLL')} </span>
                                </div>

                                <div style={infoWindowStyle.containerInfoCustomer}>
                                    <span style={infoWindowStyle.labelCustomer}>
                                        {`${driver.address}`}
                                    </span>
                                </div>

                                <div style={infoWindowStyle.containerInfo}>

                                    <div style={infoWindowStyle.containerInfoDriverHelperTruck}>

                                        <span style={infoWindowStyle.titleInfo}>Product Name</span>
                                        {
                                            (driver.inventory) ?
                                                (driver.inventory.length > 0) ?
                                                    driver.inventory.map(inv => (<span style={infoWindowStyle.labelInfo}>{`${inv.name}`}</span>))
                                                    : null
                                                : null
                                        }


                                    </div>

                                    <div style={infoWindowStyle.containerInfoDriverHelperTruck}>

                                        <span style={infoWindowStyle.titleInfo}>Total gallons</span>
                                        {(driver.inventory) ?
                                            (driver.inventory.length > 0) ?
                                                driver.inventory.map(inv => (<span style={infoWindowStyle.labelInfo}>{`${inv.total_gallons}`}</span>))
                                                : null
                                            : null
                                        }

                                    </div>

                                </div>
                            </div>
                        </InfoWindow>
                    }
                </Marker>
            )
        }
        )}
        {props.sites.map(site => {
            const colorAsign = (props.customerSelected === 0) ? site.driverColor : (site.setColor === 1) ? site.driverColor : site.driverColor;
            const hasMoreThanOneDriver = hasMoreThanOneDriverFC((site.dispatches));
            const getIconUrl = hasMoreThanOneDriver ? MoreThanOneDriverIcon(colorAsign) : getIconDispatch(colorAsign);
            const iconSize = hasMoreThanOneDriver ? 55 : 40;

            return (
                <Marker
                    key={`marker-${site.site}`}
                    icon={{
                        url: getIconUrl,
                        scaledSize: new google.maps.Size(iconSize, iconSize),


                    }}
                    title={`${site.site}`}
                    name={site.site}
                    position={site.position}
                    onClick={() => props.onChangeHoverKey(site.id)}
                >
                    {props.hoverKey === site.id &&
                        <InfoWindow
                            key={`infowindow-${site.site}`}
                            onCloseClick={() => props.onChangeHoverKey(0)}
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 200,
                                backgroundColor: 'white'
                            }}>
                                <span style={{
                                    marginBottom: 10,
                                    fontSize: 15,
                                    fontWeight: 'bold'
                                }}>{site.site}</span>
                                <span style={{
                                    marginBottom: 10,
                                    fontSize: 15,
                                    fontWeight: 'bold'
                                }}>Priority: {site.priorityOrder}</span>

                                <span style={{
                                    marginBottom: 10,
                                    fontSize: 13,
                                }}>{site.address}</span>
                                <span style={{
                                    marginBottom: 10,
                                    fontSize: 13,
                                }}>{site.city}, {site.state}</span>
                                <span style={{
                                    marginBottom: 10,
                                    fontSize: 13,
                                }}>{site.zipcode}</span>
                                <Table
                                    hidePaginationTop="true"
                                    hidePaginationBottom={false}
                                    numberPages={site.dispatches.length}
                                    columns={[
                                        { Header: "", accessor: 'urlEdit' },
                                        { Header: "", accessor: 'driverFullName' },
                                    ]}
                                    items={React.useMemo(() => site.dispatches, [site.dispatches])}
                                />
                            </div>
                        </InfoWindow>
                    }
                </Marker>
            )
        }
        )}


    </GoogleMap >
);

// function dispatchDataToTable(dispatches){
//     let data =
//     return data;
// }

export default MapContainer;
